/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  /* background: #f1f1f1; */
  background: white;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #f1f1f1;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}
.left-container {
  display: flex;
  align-items: center;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}
.workout-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}
.workout-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.workout-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

/* new workout form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
select {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
nav a {
  margin-left: 10px;
}
nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
}

.navmenu {
  padding: 20px;
  font-size: 20px;
}

/* auth forms */
form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.hello {
  text-align: center;
}

/* tabs under recommended schedule */
/* .tabs {
  display: inline-block;
  max-width: auto;
  margin: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
} */
.tabs {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #19bfe0;
  text-align: center;
  color: black;
}

.tabs:hover{
  border: 1px dotted #19bfe0;
}

.tabs a {
  color: black;
  text-decoration: none; /* Remove underline */
}

/* PRODUCTS */
.recSched {
  display: flex;
  width: 90%;
  /* align-items: center;
  justify-content: center; */
  margin-left: auto;
  margin-right: auto;
}

.box {
  border: 1px solid #f1f1f1;
  padding: 10px;
  width: calc(25% - 10px);
  gap: 10px;
}

.box1 {
  flex: 0 0 60%; /* Take up 60% of the container */
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Add gap between inner boxes */
  margin-right: 10px; /* Add margin to the right */
  justify-content: center;
}

.product-card {
  width: calc(25% - 10px);
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
}

.product-card:hover{
  border: 1px dotted black;
}

.product-card.hovered{
  border: 2px dashed #333;
}


.section-bottom {
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  background-color: lightblue;
}

.section-top::before {
  top: -5px; /* Position above the section */
}

.section-bottom {
  margin-top: auto; /* Push to the bottom of the container */
}

  
/* New CSS for the list and button styles */
.button-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.button-list li {
  margin: 5px 0;
  position: relative;
}

.button-list li button {
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color:#19bfe0;
  color: white;
  cursor: pointer;
  padding: 5px;
}

.box2 {
  flex: 0 0 40%; /* Take up 40% of the container */
  margin-left: 10px; /* Add margin to the left */
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
}

/*Minor Display*/


/*GPA Simulator*/
.mod-table {
  min-width: 500px;
  margin: 5px;
}

.mod-table td{
  padding: 20px;
}

.gpa-table {
  min-width: 100px;
  margin: 5px;
}

.gpa-table td{
  padding: 10px;
}

.gpa-table tr td button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

.sem-tabs {
  display: inline-block;
  max-width: auto;
  margin: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.active-tab {
  display: inline-block;
  max-width: auto;
  margin: 10px;
  padding: 20px;
  background: var(--primary);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: #fff;
}

/* .product {
  position: relative;
  overflow: hidden;
  padding: 20px;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
}

.product-category {
  padding: 0 10vw;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.product-container {
  padding: 0 10vw;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.product-container::-webkit-scrollbar {
  display: none;
}

.product-card {
  flex: 0 0 auto;
  width: 450px;
  height: 250px;
  margin-right: 40px;
  background-color:#fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display:flex;
}

.product-card.hovered{
  border: 2px dashed #333;
}


.pre-btn,
.nxt-btn {
  border: none;
  width: 10vw;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  cursor: pointer;
  z-index: 8;
}

.pre-btn {
  left: 0;
  transform: rotate(180deg);
}

.nxt-btn {
  right: 0;
}

img {
  width: 50px;
  height: 50px;
}

.pre-btn img,
.nxt-btn img {
  opacity: 0.2;
}

.pre-btn:hover img,
.nxt-btn:hover img {
  opacity: 1;
}

.collection-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.collection {
  position: relative;
}

.collection img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 50px;
  text-transform: capitalize;
}

.collection:nth-child(3) {
  grid-column: span 2;
  margin-bottom: 10px;
} */